<template>
    <div>
        <v-row>
            <v-col cols="3">
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    persistent
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateRangeText"
                        label="Date Range"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                    ></v-text-field>
                    </template>
                    <v-row style="background:white">
                        <v-col :cols="rangeDateData">
                            <v-radio-group
                            :mandatory="false"
                            class="large"
                            column
                            v-model="dateData"
                            style="margin-left:5px;">
                                <v-radio label="Today" value="today" @click="loadData"></v-radio>
                                <v-radio label="Yesterday" value="yesterday" @click="loadData"></v-radio>
                                <v-radio label="Last 7 days" value="last7days" @click="loadData"></v-radio>
                                <v-radio label="Last 30 days" value="last30days" @click="loadData"></v-radio>
                                <v-radio label="Custom" value="custom"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col :cols="rangeCalender" v-if="dateData == 'custom'">
                            <v-date-picker
                            v-model="dates"
                            range
                            scrollable
                            @change="loadData"
                            v-if="menu2"
                            ></v-date-picker>
                        </v-col>
                    </v-row>
                    <!-- <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker> -->
                </v-menu>
            </v-col>
        </v-row>

        <v-data-table
        :headers="headers"
        :items="profitData.products"
        class="elevation-1"
        :loading="loading"
        :search="search"
        :footer-props="{ 'items-per-page-options': [10, 50, 100, -1] }"
        v-if="profitData.products"
        >
        <template v-slot:top>
            <v-toolbar flat color="white">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search Product"
                single-line
                hide-details
                class="shrink mx-4"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <template>
                <b>Total Net Profit:
                <span style="color: green">
                    ${{ profitData.total_net_profit }}
                </span> - 
                Google Ads:
                <span style="color: green">
                    ${{ profitData.total_google_ads }}
                </span> - 
                Tiktok Ads:
                <span style="color: green">
                    ${{ profitData.total_tiktok_ads }}
                </span>
                </b>
            </template>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="exportData">Export</v-btn>
            </v-toolbar>
        </template>

        <template v-slot:item.product_thumbnail="{ item }">
            <div>
                <img :src="item.product_thumbnail" height="100px"/>
            </div>
        </template>
        </v-data-table>
    </div>
</template>

<script>

import axios from "axios";
import moment from "moment-timezone"

    export default {
        name:"ProfitDashboard",
        data:() => ({
            menu2: false,
            dates:[],
            headers:[
                {
                    text: "Product Thumb",
                    value: "product_thumbnail",
                    class: "sticky-header white",
                },
                {
                    text: "Product Type",
                    value: "product_type",
                    class: "sticky-header white",
                },
                {
                    text: "Product Title",
                    value: "product_title",
                    class: "sticky-header white",
                },
                {
                    text: "Orders",
                    value: "orders",
                    class: "sticky-header white",
                },
                {
                    text: "Sales",
                    value: "sales",
                    class: "sticky-header white",
                },
                {
                    text: "Basecost",
                    value: "basecost",
                    class: "sticky-header white",
                },
                {
                    text: "Revenue",
                    value: "revenue",
                    class: "sticky-header white",
                },
                {
                    text: "Processing Fee",
                    value: "processing_fee",
                    class: "sticky-header white",
                },
                {
                    text: "Gross Profit",
                    value: "gross_profit",
                    class: "sticky-header white",
                },
                {
                    text: "Discount",
                    value: "discount",
                    class: "sticky-header white",
                },
                {
                    text: "Net Profit",
                    value: "net_profit",
                    class: "sticky-header white",
                },
            ],
            profitData:[],
            loading:false,
            search:'',
            dateData:'',
            rangeCalender:0,
            rangeDateData:12,
        }),
        computed: {
            dateRangeText() {
            return this.dates.join(" ~ ");
            },
        },
        watch:{
            dateData(){
                this.dates=[]
                if(this.dateData == 'custom'){
                    this.rangeCalender = 8
                    this.rangeDateData = 4
                }else{
                    this.rangeCalender = 0
                    this.rangeDateData = 12
                    this.dateCalculate(this.dateData)
                }
            }
        },
        methods:{
            dateCalculate(data){
                let startDate = null;
                let endDate = null
                if(data == 'today'){
                    startDate = moment(new Date()).format("YYYY-MM-DD");
                    endDate = moment(new Date()).format("YYYY-MM-DD");
                    this.dates.push(startDate)
                    this.dates.push(endDate)
                }else if(data == 'yesterday'){
                    startDate = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                    endDate = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                    this.dates.push(startDate)
                    this.dates.push(endDate)
                }else if(data == 'last7days'){
                    startDate = moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");
                    endDate = moment(new Date()).format("YYYY-MM-DD");
                    this.dates.push(startDate)
                    this.dates.push(endDate)
                }else if(data == 'last30days'){
                    startDate = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                    endDate = moment(new Date()).format("YYYY-MM-DD");
                    this.dates.push(startDate)
                    this.dates.push(endDate)
                }
            },
            async loadData(){
                this.loading = true
                await this.$root.$refs.app.checkToken();

                let queryParams = {}
                if (this.dates.length > 0) {
                    if (this.dates[0] > this.dates[1]) {
                    queryParams["report_from"] = this.dates[1];
                    queryParams["report_to"] = this.dates[0];
                    } else {
                    queryParams["report_from"] = this.dates[0];
                    queryParams["report_to"] = this.dates[1];
                    }
                }

                await axios
                .get(window.domain + "reports/partner-profit", {
                    params: queryParams,
                })
                .then((response) => {
                    console.log("response: ", response);
                    this.profitData = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("error " + error);
                });                
            },
            async exportData(){
                this.loading = true
                await this.$root.$refs.app.checkToken();

                let queryParams = {}
                if (this.dates.length > 0) {
                    if (this.dates[0] > this.dates[1]) {
                    queryParams["report_from"] = this.dates[1];
                    queryParams["report_to"] = this.dates[0];
                    } else {
                    queryParams["report_from"] = this.dates[0];
                    queryParams["report_to"] = this.dates[1];
                    }
                }

                await axios
                .post(window.domain + "reports/partner-profit/export-sales", queryParams)
                .then((response) => {
                    console.log("response: ", response);
                    alert("Check your email!")
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("error " + error);
                });           
            }
        },
        mounted(){

        },
    }
</script>

<style lang="scss" scoped>

</style>