<template>
    <div>
        <v-row>
            <!-- <v-col cols="12"><div><b>Total Balance:</b> ${{ balanceData.total_balance }}</div>
            <div><b>Available Balance (T+3):</b> ${{ balanceData.available_balance }}</div></v-col> -->
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="balanceData.transactions"
                    class="elevation-1"
                    :loading="loading"                    
                    :footer-props="{ 'items-per-page-options': [10, 50, 100, -1] }"
                    v-if="balanceData.transactions"
                    >
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <h3>Cashout transactions</h3>
                        <v-divider class="mx-4" inset vertical></v-divider>                        
                        

                        <div>Total Balance: 
                            <b><span style="color: green">${{ balanceData.total_balance }}</span></b> - 
                            Available Balance (T+3): 
                            <b><span style="color: green">${{ balanceData.available_balance }}</span></b>
                        </div>

                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="50%">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Add Transaction</v-btn>
                            </template>
                            <v-card max-width="100%">
                            <v-card-title>
                                <span class="headline">Add Transaction</span>
                            </v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" v-if="informText">
                                        <v-alert type="error" style="text-align: left">
                                        {{ informText }}
                                        </v-alert>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="item.amount"
                                            label="Amount (USD)"
                                            type="number"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        Receiver:
                                        <v-radio-group
                                        v-model="item.receiver"
                                        :mandatory="false"
                                        class="large"
                                        row
                                        style="text-align: center; display: inline">
                                            <v-radio label="GBA" value="GBA"></v-radio>
                                            &nbsp;
                                            <v-radio label="Partner" value="partner"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="item.note"
                                            label="Note"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <!-- <v-btn color="blue darken-1" text @click="check">Check</v-btn> -->
                                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                                v-if="item.amount && item.note"
                                type="submit"
                                >Save</v-btn>
                                <v-btn disabled
                                v-else
                                >Save</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>

                        </v-toolbar>
                    </template>

                    <template v-slot:item.inserted_date="{ item }">
                        <div>{{ item.inserted_date }}</div>
                    </template>

                    <template v-slot:item.amount="{ item }">
                        <div>${{ item.amount }}</div>
                    </template>
                    </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment-timezone";

    export default {
        name:"BalanceDashboard",
        data:() => ({
            balanceData:[],
            loading:'',
            headers:[
                {
                    text: "Insert Date",
                    value: "inserted_date",
                    class: "sticky-header white",
                },
                {
                    text: "Note",
                    value: "note",
                    class: "sticky-header white",
                },
                {
                    text: "Receiver",
                    value: "receiver",
                    class: "sticky-header white",
                },
                {
                    text: "Amount",
                    value: "amount",
                    class: "sticky-header white",
                },
            ],
            item:{},
            dialog:false,
            informText:'',
        }),
        watch: {},
        methods:{
            async loadData(){
                this.loading = true
                await this.$root.$refs.app.checkToken();

                await axios
                .get(window.domain + "partner-transactions", {params:""})
                .then((response) => {
                    
                    this.balanceData = response.data.data;
                    //this.balanceData.transactions = this.balanceData.transactions.filter(t => t.inserted_date = moment(t.inserted_date).format("YYYY-MM-DD HH:MM:SS"))

                    for(let t of this.balanceData.transactions){
                        t.inserted_date = moment(t.inserted_date).format("YYYY-MM-DD HH:mm:ss")
                    }

                    console.log("response: ", response," - this.balanceData: ",this.balanceData);
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("error " + error);
                });                
            },

            close() {
                this.dialog = false;
                this.informText = "";
                this.item = {};
            },

            validation(){

            },

            async save(){
                await this.$root.$refs.app.checkToken();

                await axios
                .post(window.domain + "partner-transactions", this.item)
                .then((response) => {
                    console.log("response: ", response," - this.balanceData: ",this.balanceData);
                    if(response.data.error_message == '')
                    {
                        this.close();
                        this.loadData()
                    }
                    else{
                        this.informText = response.data.error_message
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });               
            }
        },
        async mounted(){
            await this.loadData();
        }
    }
</script>

<style lang="scss" scoped>

</style>