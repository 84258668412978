<template>
    <div v-if="$store.state.gbaUserPermissions.partner">
        <br/>
        <v-col cols="12">
            <v-card>
                <v-tabs
                background-color="cyan"
                center-active
                dark
                v-model="tab"
                >
                    <v-tab href="#tab-1" 
                    v-if="$store.state.gbaUserPermissions.partner.report_profit > 0">PARTNER PROFIT</v-tab>
                    <v-tab href="#tab-2" 
                    v-if="$store.state.gbaUserPermissions.partner.list_transactions > 0">PARTNER BALANCE</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1" >
                        <v-col cols="12">
                            <Profit/>
                        </v-col>
                    </v-tab-item>

                    <v-tab-item value="tab-2" >
                        <v-col cols="12">
                            <Balance/>
                        </v-col>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-col>
    </div>
</template>

<script>
    import Profit from "./Profit/c-profit-dashboard.vue"
    import Balance from "./Balance/c-balance-profit.vue"
    
    export default {
        name:"DashboardContainer",
        components:{
            Profit,
            Balance
        },
        data:()=>({
            tab: null,
        }),
    }
</script>

<style lang="scss" scoped>

</style>