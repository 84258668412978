var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.balanceData.transactions)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.balanceData.transactions,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [10, 50, 100, -1] }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('h3',[_vm._v("Cashout transactions")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',[_vm._v("Total Balance: "),_c('b',[_c('span',{staticStyle:{"color":"green"}},[_vm._v("$"+_vm._s(_vm.balanceData.total_balance))])]),_vm._v(" - Available Balance (T+3): "),_c('b',[_c('span',{staticStyle:{"color":"green"}},[_vm._v("$"+_vm._s(_vm.balanceData.available_balance))])])]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("Add Transaction")])]}}],null,false,2425373079),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Transaction")])]),_c('v-card-text',[_c('v-row',[(_vm.informText)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticStyle:{"text-align":"left"},attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.informText)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Amount (USD)","type":"number"},model:{value:(_vm.item.amount),callback:function ($$v) {_vm.$set(_vm.item, "amount", $$v)},expression:"item.amount"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Receiver: "),_c('v-radio-group',{staticClass:"large",staticStyle:{"text-align":"center","display":"inline"},attrs:{"mandatory":false,"row":""},model:{value:(_vm.item.receiver),callback:function ($$v) {_vm.$set(_vm.item, "receiver", $$v)},expression:"item.receiver"}},[_c('v-radio',{attrs:{"label":"GBA","value":"GBA"}}),_vm._v("   "),_c('v-radio',{attrs:{"label":"Partner","value":"partner"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Note"},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),(_vm.item.amount && _vm.item.note)?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit"},on:{"click":_vm.save}},[_vm._v("Save")]):_c('v-btn',{attrs:{"disabled":""}},[_vm._v("Save")])],1)],1)],1)],1)]},proxy:true},{key:"item.inserted_date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.inserted_date))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("$"+_vm._s(item.amount))])]}}],null,false,1421593330)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }