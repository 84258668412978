<template>
    <dashboard />
  </template>
  
  <script>
  // @ is an alias to /src
  import dashboard from "@/components/Dashboard/c-dashboard-container.vue";
  
  export default {
    name: "Dashboard",
    components: {
        dashboard,
    },
  };
  </script>
  